@import "~bootstrap/dist/css/bootstrap.min.css";

.bg-gesport {
    background-color: #0170cc;
}

.card {
    border-bottom: 1px solid #DFDFDF!important;
}

.origin-selector {
    display:none;
}
#queryButton {
    margin-top:7px;
}

.templates {
    display:none;
}
.result-table {
    table-layout: fixed;
}
.result-table > td{
    max-width: 33%;
    width:33%;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index:999;
}

.scroll-button {
    color: #fff;
    margin: 0;
    position: relative;
    left: 17px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index:999;
}
#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
    color: #fff;
    top: 5px;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
#loading {
    position: fixed; /* or absolute */
    top: 40%;
    left: 40%;
}
#loading > small {
    margin-left:-60px;
}
.table td, .table th {
    border: none;
}

table.table.table-condensed {
    border: 1px solid black;
}
#notFound {
    display:block;
    margin:auto;
}

#seasonSelect {
    display:none;
}

.seasonDiv {
    display:none;
}